import React from 'react'
import Nav from '../components/Nav'
import { Button } from '@nextui-org/react'

function NotFound() {

    const goBack = () => {
        window.history.back()
    }
    
  return (
   <>
    <Nav />
    <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-4xl font-bold">404</h1>
        <p className="mb-8">Página no encontrada</p>
        <Button onClick={goBack} color="primary" className="mt-4">
            Regresar al inicio
        </Button>
    </div>
    </>

  )
}

export default NotFound