import React, { useState } from "react";
import Nav from "../components/Nav";
import PasswordInput from "../components/PasswordInput";
import { Button } from "@nextui-org/react";
import api from "../lib/api";
import { useNavigate } from "react-router-dom";

function Auth() {
  const [token, setToken] = useState(null);
  const [err, setErr] = useState(null);
  const navigate = useNavigate();

  const handleAuth = async () => {
    try {
      const res = await api.post("/auth/login", { token });
      if (res.data.success) {
          navigate("/productos");
      }
    } catch (error) {
      setErr("Token incorrecto");
    }
  };

  return (
    <>
      <Nav />
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-4xl font-bold">Speedtech admin panel</h1>
        <p className="mb-8">Ingresa tu token e acceso para continuar</p>
        <PasswordInput err={err} onInput={setToken} />
        {token && (
          <Button onPress={handleAuth} color="primary" className="mt-4">
            Ingresar
          </Button>
        )}
      </div>
    </>
  );
}

export default Auth;
