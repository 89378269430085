import React from "react";
import Nav from "../components/Nav";
import useBilling from "../hooks/useBilling";
import { Snippet } from "@nextui-org/react";
import { BILL_STATUS } from "../lib/const/bill";
import { Link } from "react-router-dom";

function Billing() {
  const { billing } = useBilling();

  return (
    <>
      <Nav />

      <main className="flex flex-col items-center mt-12">
        <header className="md:w-1/2 flex justify-between">
          <div>
            <h3 className="font-bold text-2xl">Facturas</h3>
            <p className="text-sm text-gray-500">
              Aquí puedes ver las facturas de tus clientes
            </p>
          </div>

          <Link to="/facturacion/nueva" className="mt-4">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Crear factura
            </button>
          </Link>
        </header>

        <section className="md:w-1/2">
          <table>
            <thead>
              <tr className="w-full mt-8 grid grid-cols-6">
                <th className="text-left">Descripción</th>
                <th className="text-left">Monto</th>
                <th className="text-left">Estado</th>
                <th className="text-left">Link</th>
              </tr>
            </thead>
            <tbody>
              {billing.map((bill) => (
                <tr className="w-full mt-8 grid grid-cols-6" key={bill.code}>
                  <td>{bill.description}</td>
                  <td>₡{bill.total}</td>
                  <td>{BILL_STATUS[bill.status]}</td>
                  <td>
                    <Snippet
                      symbol={false}
                    >{`${process.env.REACT_APP_FRONTEND}/bill/${bill.code}`}</Snippet>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </>
  );
}

export default Billing;
