import ProductsTable from "..//components/ProductsTable";
import React, { useEffect } from "react";
import Nav from "../components/Nav";
import api from "../lib/api";
import AddProduct from "../components/AddProduct";

function Products() {
  const [products, setProducts] = React.useState([]);


  const addLocalProduct = (product) => {
    setProducts([product, ...products]);
  }

  useEffect(() => {
    const getProductos = async () => {
      const { data } = await api.get("/products");
      setProducts(data);
    };
    getProductos();
  }, []);

  return (
    <>
      <Nav />
      <main className="px-32 mt-16">
        <header className="flex justify-between">
            <h1 className="bold">Productos</h1>

          <AddProduct products={products} setProducts={addLocalProduct}/>
        </header>
      <ProductsTable receivedProducts={products} />
      </main>
    </>
  );
}

export default Products;
