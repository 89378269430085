import React from "react";
import Nav from "../components/Nav";
import useDealers from "../hooks/useDealers";
import { Input, User } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

function Dealers() {
  const { dealers, changeDealerState } = useDealers();

  return (
    <>
      <Nav />

      <main>
        <div className="flex w-screen justify-end pr-12 gap-8">
          <Input className="w-[400px]" placeholder="Buscar distribuidor" />
          <select name="" id="">
            <option value={true}>Activos</option>
            <option value={false}>Inactivos</option>
          </select>
        </div>

        <section className="mt-12">
          {dealers.map((dealer) => (
            <div key={dealer.id} className="flex justify-between w-screen px-12 py-4 border-b-2 border-gray-200">
              <User
                name={dealer.name + (dealer.active ? " ✅" : "")}
                description={`${dealer.id}`}
                avatarProps={{
                  src: `${process.env.REACT_APP_API}/files/${dealer.logo}`,
                }}
              />

              <div className="ultraSmall">
                <p className="text-gray-500">{dealer.email}</p>
                <p className="text-gray-500">Tel {dealer.phone}</p>

                <p className="text-gray-500">
                  {dealer.city} ,{dealer.country}
                </p>
              </div>

              <div className="ultraSmall">
                <p className="text-gray-500">{dealer.address}</p>
              </div>

              <div className="flex gap-4">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https:wa.me/${dealer.whatsapp}`}
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className="text-gray-500"
                  />
                </a>

                <a target="_blank" rel="noreferrer" href={dealer.googleMaps}>
                  <FontAwesomeIcon icon={faMap} className="text-gray-500" />
                </a>

                <a target="_blank" rel="noreferrer" href={dealer.website}>
                  <FontAwesomeIcon icon={faGlobe} className="text-gray-500" />
                </a>
              </div>

              <div className="flex gap-4">
                {!dealer.active ? (
                  <button onClick={() => changeDealerState(dealer.id)} className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg">
                    Activar
                  </button>
                ) : (
                  <button onClick={() => changeDealerState(dealer.id)} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg">
                    Desactivar
                  </button>
                )}
              </div>
            </div>
          ))}
        </section>
      </main>
    </>
  );
}

export default Dealers;
