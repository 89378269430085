import { useState } from "react";
import { useEffect } from "react";
import api from "../lib/api";

const useDealers = () => {

    const [dealers, setDealers] = useState([]);
    
    useEffect(() => {
        api.get("/dealers").then((res) => {
        setDealers(res.data);   
        });
    }, []);

    function changeDealerState(id) {
        const dealer = dealers.find((dealer) => dealer.id === id);
        dealer.active = !dealer.active;
        setDealers([...dealers]);
        api.put(`/dealers/${id}`, dealer);
    }
    
    return {dealers, changeDealerState};


}

export default useDealers;