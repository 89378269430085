import { toast } from "sonner";
import apiv2 from "../lib/apiv2";
import { useState } from "react";

const useNewBill = () => {
  const [newBill, setNewBill] = useState({
    description: "",
    total: null,
    code: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setNewBill({
      ...newBill,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!newBill.description || !newBill.total) {
      toast.error("Debes completar todos los campos");
      setLoading(false);
      return;
    }
    try {
      await apiv2.post("/manualBill", newBill);
      toast.success("Factura creada");
      setNewBill({
        description: "",
        total: null,
        code: "",
      });
    } catch {
      toast.error("Error al crear factura");
    }
    setLoading(false);
  };

  return { newBill, loading, handleChange, handleSubmit };
};

export default useNewBill;
