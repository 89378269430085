import { useEffect, useState } from "react";
import apiv2 from "../lib/apiv2";
import { toast } from "sonner";

const useBilling = () => {
  const [billing, setBilling] = useState([]);

  useEffect(() => {
    const fetchBilling = async () => {
      try {
        const { data } = await apiv2.get("/manualBill");
        setBilling(data);
      } catch {
        toast.error("Error al obtener facturas");
        setBilling([]);
      }
    };

    fetchBilling();
  }, []);

  return { billing };
};

export default useBilling;
