import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./views/Auth";
import Products from "./views/Products";
import { Toaster } from "sonner";
import NotFound from "./views/NotFound";
import Dealers from "./views/Dealers";
import Billing from "./views/Billing";
import NewBill from "./views/NewBill";

function App() {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="/productos" element={<Products />} />
          <Route path="/distribuidores" element={<Dealers />} />
          <Route path="/facturacion" element={<Billing />} />
          <Route path="/facturacion/nueva" element={<NewBill />} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
