import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  // Button,
  Avatar,
} from "@nextui-org/react";
import { Link } from "react-router-dom";

export default function App() {
  return (
    <Navbar shouldHideOnScroll>
      <NavbarBrand>
        <Avatar src="st.jpg" />
        <p className="font-bold text-inherit">Speedtech</p>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Link to="/productos">Productos</Link>
        </NavbarItem>
        <NavbarItem>
          <Link to="/distribuidores">Distribuidores</Link>
        </NavbarItem>
        <NavbarItem>
          <Link to="/facturacion">Facturación</Link>
        </NavbarItem>
      </NavbarContent>
      {/* <NavbarContent justify="end">
        <NavbarItem className="hidden lg:flex">
          <Link href="#">Login</Link>
        </NavbarItem>
        <NavbarItem>
          <Button as={Link} color="primary" href="#" variant="flat">
            Sign Up
          </Button>
        </NavbarItem>
      </NavbarContent> */}
    </Navbar>
  );
}
