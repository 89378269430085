import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
} from "@nextui-org/react";
import api from "../lib/api";
import { toast } from "sonner";

export default function App({ setProducts }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [name, setName] = React.useState(null);

  const handdleSubmit = async () => {
    try {
      const { data } = await api.post("/products", { name });
      setProducts({id: data, name, created: data.created});
      toast.success("Producto creado");
    } catch (error) {
      toast.error("Error al crear el producto");
    }
  };

  return (
    <>
      <Button variant="light" color="success" onPress={onOpen}>
        Nuevo
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Agregar producto
              </ModalHeader>
              <ModalBody>
                <Input
                  color="primary"
                  variant="bordered"
                  label="Nombre"
                  placeholder="Nombre del producto"
                  onInput={(e) => setName(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Cancelar
                </Button>
                {name && (
                  <Button
                    onPress={() => {
                      handdleSubmit();
                      onClose();
                    }}
                    className="white"
                    color="success"
                  >
                    Crear
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
