import React from "react";
import { Input } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function App({ onInput, err }) {
  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <Input
    validationState={err ? "invalid" : "default"}
    errorMessage={err}
      label="Token"
      variant="bordered"
      color="primary"
      placeholder="Ingresa tu token de acceso"
      onInput={(e) => onInput(e.target.value)}
      endContent={
        <button
          className={`focus:outline-none`}
          type="button"
          onClick={toggleVisibility}
        >
          {isVisible ? (
            <FontAwesomeIcon
              icon={faEye}
              className="text-2xl text-default-400 pointer-events-none"
            />
          ) : (
            <FontAwesomeIcon
              icon={faEyeSlash}
              className="text-2xl text-default-400 pointer-events-none"
            />
          )}
        </button>
      }
      type={isVisible ? "text" : "password"}
      className="max-w-xs"
    />
  );
}
